import './App.css';
import {Card, Image, Carousel, Flex} from 'antd';

/* https://ant.design/components/overview */ 

/* 2000 Ford Mustang Car */
import mustang1 from "./assets/cars/fm1.jpg";
import mustang2 from "./assets/cars/fm2.jpg";
import mustang3 from "./assets/cars/fm3.jpg";
import mustang4 from "./assets/cars/fm4.jpg";

import comingsoon from "./assets/comingsoon.jpg";


function App() {
  return (
    <div>
        <div className="App">
          <div className="hero-image">
            <header className="App-header-2">
              <br/>
              BBA Auto Sales<br/>
              610 N. Cunningham Urbana, IL. 61802 <br/>
            </header>
          </div>
        </div>
        <div className='separator'>Contact Us at 217-344-2020</div>
        <div> 
        <Flex wrap justify='space-evenly' align='center'>
        <Card title="2000 Ford Mustang - $15,000" variant="borderless" style={{ width: 500 }}>
            <p>95K Miles, V8 Manual, New oil change, new brakes, new exhaust, excellent condition.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {mustang1}/>                 
                </div>
                <div>
                <Image width={500} src= {mustang2}/>                 
                </div>
                <div>
                <Image width={500} src= {mustang3}/>                 
                </div>
                <div>
                <Image width={500} src= {mustang4}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2012 Cadillac CTS - $11,500" variant="borderless" style={{ width: 500 }}>
            <p>2 Door Coupe, V6, 103K, Roof, Excellent Shape.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2012 Dodge Durango RT - $13,000" variant="borderless" style={{ width: 500 }}>
            <p>118K Miles, V8, Leather, Clean, Excellent Condition.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2015 Nissan Juke - $9,500" variant="borderless" style={{ width: 500 }}>
            <p>103K Miles, AWD, Nice and Clean.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2015 Nissan Juke - $9,500" variant="borderless" style={{ width: 500 }}>
            <p>103K Miles, AWD, Nice and Clean.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2014 Cadillac CTS 4 - $12,500" variant="borderless" style={{ width: 500 }}>
            <p>120K Miles, Loaded, Leather, Excellent Find.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2018 Dodge Durango - $20,500" variant="borderless" style={{ width: 500 }}>
            <p>92K Miles, 3rd Row, AWD, Clean, 6 Cyl.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="1992 Ford Mustang Convertible - $3,500" variant="borderless" style={{ width: 500 }}>
            <p>80K Miles, 4 Cyl, Good Interior, Alloys.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2012 Mustang Convt - $10,500" variant="borderless" style={{ width: 500 }}>
            <p>113K Miles, Red, Great Tires, Nice.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2015 Lincoln MKC - $11,500" variant="borderless" style={{ width: 500 }}>
            <p>120K Miles, Leather, Panoramic Roof, Camera, Fresh Tune Up, New Battery.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2011 Cadillax SRX - $10,500" variant="borderless" style={{ width: 500 }}>
            <p>120K Miles, Leather, Panoramic Roof, Excellent.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="1988 Mercedes 560 SL - $12,500" variant="borderless" style={{ width: 500 }}>
            <p>81K Miles, Runs and Drives, Great, New Tires.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2011 Dodge Nitro - $5,900" variant="borderless" style={{ width: 500 }}>
            <p>135K Miles, All Power, Clean, Dependable.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2016 Cadillac Escalade - $30,000" variant="borderless" style={{ width: 500 }}>
            <p>80K, Premium, Loaded, Excellent Condition.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2016 Cadillac Escalade - $30,000" variant="borderless" style={{ width: 500 }}>
            <p>80K, Premium, Loaded, Excellent Condition.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2007 Dodge Charger RT - $10,500" variant="borderless" style={{ width: 500 }}>
            <p>107K Miles, Auto, Rear Wheel Drive.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2001 Dodge Ram - $4,500" variant="borderless" style={{ width: 500 }}>
            <p>Auto, Red, AC, All Power.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2016 Challenger SE - $16,500" variant="borderless" style={{ width: 500 }}>
            <p>43K Miles, Good Tires, Carbon Fiber Interior Accents.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2016 Jeep Renegade - $13,500" variant="borderless" style={{ width: 500 }}>
            <p>65K Miles, 75th Annivesary, 6 Spd., Panoramic Roof.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2013 Buick Encore - $7,600" variant="borderless" style={{ width: 500 }}>
            <p>100K Miles, FWD, 4 Cyl., Auto.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2015 Ford Explorer - $14,000" variant="borderless" style={{ width: 500 }}>
            <p>115k Miles, Camera, Bluetooth, V6, Nav, Good Tires.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2011 Jeep Grd Cherokee - $10,500" variant="borderless" style={{ width: 500 }}>
            <p>144K Miles, 4x4, Leather, New Battery.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2000 Ford Mustang - $15,500" variant="borderless" style={{ width: 500 }}>
            <p>95K Miles, V8, Manual, New Brakes, After Market Exhaust, Leather.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2000 Ford Mustang - $15,500" variant="borderless" style={{ width: 500 }}>
            <p>95K Miles, V8, Manual, New Brakes, After Market Exhaust, Leather.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2015 Nissan PathFinder - $9,500" variant="borderless" style={{ width: 500 }}>
            <p>98K Miles, All Wheel Drive, 3RD Row, Nice and Clean.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="1999 Honda CBR 900rr - $4,800" variant="borderless" style={{ width: 500 }}>
            <p>Fresh Service, 43K Miles, Great Shape.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2012 Mustang Convertible - $11,500" variant="borderless" style={{ width: 500 }}>
            <p>125K Miles, All Power, V6, Runs and Drives.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
          <Card title="2009 VW Golf GTI - $5,900" variant="borderless" style={{ width: 500 }}>
            <p>152K Miles, Roof, Auto, FWD.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {comingsoon}/>                 
                </div>
            </Carousel>
          </Card>
        </Flex>
        </div>
        <div>
          <div className='separator'>About Us</div>
          <Flex justify='space-evenly' align='center' vertical>
            <p className='App-info-test' style={{ maxWidth: 800 }}>BBA Auto Sales is family owned and operated. We have been part of the Urbana community for the last 30+ years. Being a small family owned dealership allows us to provide quality cars while keeping our prices low.</p>
            <p className='App-info-test' style={{ maxWidth: 800 }}>Antonio Barreda - Owner</p>
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3051.0649338166468!2d-88.20565628435764!3d40.11855638164319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880cd79c8a9ba8bb%3A0xbe55a6bc792bdab1!2sBBA%20Auto%20Sales%20LLC!5e0!3m2!1sen!2sus!4v1578122642967!5m2!1sen!2sus"
              width="390px"
              height="350px"
              display="initial"
              title="YouTube Video"
          />
          </Flex>
        </div>
        <div className='separator' style={{ height: 100 }}>Copyright @ 2025 BBA Auto Sales</div>
    </div>
  );
}

export default App;
